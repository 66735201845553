export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22')
];

export const server_loads = [0,2];

export const dictionary = {
		"/": [3],
		"/guide": [4],
		"/login": [5],
		"/manage/email": [6],
		"/manage/users": [7],
		"/manage/waitlist": [8],
		"/panel": [9,[2]],
		"/panel/accounts": [10,[2]],
		"/panel/accounts/add": [14,[2]],
		"/panel/accounts/[id]": [11,[2]],
		"/panel/accounts/[id]/finish": [12,[2]],
		"/panel/accounts/[id]/report": [13,[2]],
		"/panel/plans": [~15,[2]],
		"/panel/profile": [16,[2]],
		"/panel/test": [17,[2]],
		"/privacy": [18],
		"/signin": [19],
		"/signout": [20],
		"/terms": [21],
		"/wtf": [22]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';